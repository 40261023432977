
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const WELCOME_SEEN = "WELCOME_SEEN";


//common
export const GET_CITIES = "GET_CITIES";
export const GET_STATES = "GET_STATES";
export const GET_CITIES_OF_STATES = "GET_CITIES_OF_STATES";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_GERNES = "GET_GERNES";
export const GET_LANGUAGES = "GET_LANGUAGES";
export const GET_EVENTS = "GET_EVENTS";
export const GET_EVENT_MODES = "GET_EVENT_MODES";
export const GET_BANKS = "GET_BANKS";
export const GET_BRANCHES = "GET_BRANCHES";
export const GET_ID_PROOFS = "GET_ID_PROOFS";
export const GET_ADDRESS_PROOFS = "GET_ADDRESS_PROOFS";

//artist
export const SET_ARTIST_BANK_DETAILS = "SET_ARTIST_BANK_DETAILS";
export const SET_ARTIST_PHOTO_ID_PROOF = "SET_ARTIST_PHOTO_ID_PROOF";
export const SET_ARTIST_ADDRESS_PROOF = "SET_ARTIST_ADDRESS_PROOF";
export const SET_ARTIST_REFERENCES = "SET_ARTIST_REFERENCES";
export const GET_ARTIST_PROOF_DATA = "GET_ARTIST_PROOF_DATA";
export const REMOVE_ARTIST_MEDIA = "REMOVE_ARTIST_MEDIA";
export const ARTIST_APPLICATION_SUBMIT = "ARTIST_APPLICATION_SUBMIT";
export const ARTIST_IS_REJECTED = "ARTIST_IS_REJECTED";



//reset State
export const STATE_RESET = "STATE_RESET";

//jusge
export const JUDGE_APPLICATIONS = "JUDGE_APPLICATIONS";
export const ARTIST_APPLICATION_DATA = "ARTIST_APPLICATION_DATA";
export const JUDGE_APPLICATIONS_FEED_LOGS = "JUDGE_APPLICATIONS_FEED_LOGS";

