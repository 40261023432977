import React from 'react';

export default function ThreeDotLoader() {
	return (
		<div className="threeDotLoader">
	        <div></div>
	        <div></div>
	        <div></div>
	        <div></div>
	        <div></div>
	        <div></div>
	    </div>
    )
}